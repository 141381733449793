


















































































































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { IRecruitParams } from '@/interfaces';
import { readUserProfile } from '@/store/main/getters';
import { readSessionData } from '@/store/sessionData/getters';
import { readLucidFeasibility, readLucidPrice, readLucidSampleProbability, readNumberOfParticipants } from '@/store/recruitData/getters';
import { readHasAdminAccess } from '@/store/main/getters';
import {
  dispatchGetNumberOfParticipants,
  dispatchActionGetLucidAudienceEstimate
} from '@/store/recruitData/actions';
import store from '@/store';

@Component
export default class RecruitExternal extends Vue {
  @Prop(String) public countryLang!: string;

  public valid = true;
  public confirmRecruitDialog = false;
  public getCreditsDialog = false;
  public countryLangToId = {
    'UK': 8,
    'US': 9,
    'CN': 1,
    'SE': 23,
    'FR': 4,
    'DE': 5,
    'ES': 6,
  }
  public quota = 1;
  public gender_balance = 50;
  public age_range = [18, 99];
  public nrOfMatchingParticipants = 0;

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public params: IRecruitParams = {
    country_lang_id: this.countryLangToId[this.countryLang],
    quota: this.quota,
    age_range: this.age_range,
    gender_balance: this.gender_balance
  }

  @Watch('age_range') async onAgeRangeChanged() {
    this.params.age_range = this.age_range;
    if ((this.params.country_lang_id == 8) || (this.params.country_lang_id == 9)) {
      await dispatchGetNumberOfParticipants(store, { id: this.$route.params.id, params: this.params });
    }
  }

  @Watch('gender_balance') async onGenderBalanceChanged() {
    this.params.gender_balance = this.gender_balance;
    if ((this.params.country_lang_id == 8) || (this.params.country_lang_id == 9)) {
      await dispatchGetNumberOfParticipants(store, { id: this.$route.params.id, params: this.params });
    }
  }

  @Watch('quota') onQuotaChanged() {
    this.params.quota = this.quota;
  }

  @Watch('countryLang') onLangChanged() {
    this.params.country_lang_id = this.countryLangToId[this.countryLang];
  }

  get nrOfQuestions() {
    return readSessionData(this.$store).questions_with_data.length;
  }

  get nrOfParticipants() {
    return readNumberOfParticipants(this.$store);
  }

  get lucidFeasibility() {
    return readLucidFeasibility(this.$store);
  }
  get lucidPrice() {
    return readLucidPrice(this.$store);
  }
  get lucidSampleProbability() {
    return readLucidSampleProbability(this.$store);
  }
  get enoughCredits() {
    return (
      this.quota * this.nrOfQuestions <= readUserProfile(this.$store)!.credits
    );
  }

  get userCredits() {
    return (
      readUserProfile(this.$store)!.credits
    );
  }

  public countrySelect = ['UK', 'US', 'CN', 'SE', 'FR', 'DE', 'ES'];

  public reformattedCountry = {
    'UK': 'UK',
    'US': 'US',
    'CN': 'Chinese',
    'SE': 'Swedish',
    'FR': 'French',
    'DE': 'German',
    'ES': 'Spanish',
  }

  public selectedPanelCompany = {
    'UK': ' via Prolific',
    'US': ' via Prolific',
    'CN': ' via Lucid',
    'SE': ' via Lucid',
    'FR': ' via Lucid',
    'DE': ' via Lucid',
    'ES': ' via Lucid',
  }

  public maxValue() {
    if (this.hasAdminAccess) {
      return 1000;
    }
    else {
      if (this.countryLang == 'UK') {
        return 500;
      }
      if (this.countryLang == 'US') {
        return 500;
      }
      if (this.countryLang == 'CN') {
        return 200;
      }
      if (this.countryLang == 'SE') {
        return 150;
      }
    }
  }

  async created() {
    if ((this.params.country_lang_id == 8) || (this.params.country_lang_id == 9)) {
      await dispatchGetNumberOfParticipants(store, { id: this.$route.params.id, params: this.params });
    } else {
      this.params.number_of_questions = this.nrOfQuestions;
      await dispatchActionGetLucidAudienceEstimate(store, { id: this.$route.params.id, params: this.params });
    }
  }

  public resetForm() {
    this.valid = true;
    this.confirmRecruitDialog = false;
    this.getCreditsDialog = false;
    this.countryLangToId = {
      'UK': 8,
      'US': 9,
      'CN': 1,
      'SE': 23,
      'FR': 4,
      'DE': 5,
      'ES': 6,
    };
    this.quota = 1;
    this.gender_balance = 50;
    this.age_range = [18, 99];
    this.nrOfMatchingParticipants = 0;
  }
  
  @Emit()
  public close() {
    return;
  }

  @Emit()
  public async save() {
    this.confirmRecruitDialog = false;
    if (await this.$validator.validateAll()) {
      const params = JSON.parse(JSON.stringify(this.params));
      this.resetForm();
      return params;
    }
  }
}
